<template>
  <v-container style="margin-left: 60px;">
    <v-row class="justify-center">
      <v-col class="mx-auto mt-5 text-center">
        <v-img class="mx-auto mb-5" src="../../../assets/discursive.svg" max-width="400"></v-img>
        <h1 class="font-weight-thin">Fonctionnalité à venir...</h1>
        <p>Proposera une mise en évidence des connecteurs du discours</p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import MixinInterviews from '@/mixins/interviews';
import MixinPatients from '@/mixins/patients';

export default {
  name: 'InterviewToolDialogic',
  mixins: [MixinPatients, MixinInterviews],
  props: {
    patientId: String,
    interviewId: String,
  },
  methods: {
    url(path) {
      return `/patient/${this.patientId}/interview/${this.interviewId}/${path}`;
    },
  },
  data: () => ({ }),
};
</script>
